import React from 'react';

export default function NotFound({ as: Component = 'a', className = '', ...rest }) {
  return (
    <Component
      className={`leading-none inline-block rounded px-6 py-4 bg-blue-700 text-white text-sm hover:bg-blue-800 ${className}`}
      {...rest}
    />
  );
}
