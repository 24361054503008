import React from 'react';
import { Link } from 'gatsby';
import Main from '../components/Main';
import Button from '../components/Button';
import Img01 from '../images/prestation-01.jpg';
import Img02 from '../images/prestation-02.jpg';
import Img03 from '../images/prestation-03.jpg';
import Img04 from '../images/prestation-04.jpg';

const Item = ({ img, heading, caption, price }) => (
  <div className="p-4 mb-12 bg-slate-50">
    <div className="md:flex">
      <img src={img} className="object-cover w-48 -mt-8 mr-12" />
      <div className="my-8">
        <p className="mb-4">
          <strong className="text-2xl">{heading}</strong>
        </p>
        <p className="mb-4">{caption}</p>
        <div className="flex items-center justify-between mb-8">
          <small>à partir de</small>
          <span className="text-2xl semibold">{`${price.toFixed(2)} € T.T.C`}</span>
        </div>
        <div>
          <Button as={Link} to="/contact">Plus d'infos</Button>
        </div>
      </div>
    </div>
  </div>
 );

export default function Prestations() {
  return (
    <Main>
      <div className="bg-white pt-12 pb-20 px-8 md:px-0">
        <div className="container mx-auto">
          <p className="mb-12">
            <strong className="font-semibold text-3xl">Nos prestations</strong>
          </p>
          <hr className="h-1 rounded-full bg-slate-200 w-64 mb-20" />
          <div className="md:grid grid-cols-2 gap-x-12 mb-16">
            <Item img={Img01} heading="Double de clés" caption="Duplicata de tous types de clés" price={4.50} />
            <Item img={Img02} heading="Ouverture de porte" caption="Ouverture de porte claquée, verrouillée ..." price={90} />
            <Item img={Img03} heading="Porte blindée" caption="Installation et pose de porte blindée" price={1300} />
            <Item img={Img04} heading="Clé de voiture" caption="Reproduction de clé de voiture" price={69.90} />
          </div>
        </div>
      </div>
    </Main>
  )
}
